import { BtnLoader } from "components/widget"
import { CModal } from "components"
import { useAddNotification, useEditBot, useTeams } from "hooks"
import { useEffect, useRef, useState } from "react"
import { ModalAddNotificationTypes } from "types"
import { methodHandelAddNotification } from "utils"
import 'react-quill/dist/quill.snow.css';
import { MultiSelect } from "react-multi-select-component";

export const ModalAddNotification = ({ setVisible, visible }: ModalAddNotificationTypes) => {
    const formRef: any = useRef()
    const { data: teams } = useTeams(visible)
    const { isLoading, mutate } = useAddNotification(setVisible)
    const [operatorList, setOperatorList] = useState<any>([])
    const [recieverList, setRecieverList] = useState<any>([]);

    const handelAdd = (e: any) => {
        e.preventDefault()
        methodHandelAddNotification(formRef,recieverList, mutate)
    }

    useEffect(() => {
        if (teams?.data?.data) {
            let list = []
            for (let i of teams?.data?.data) {
                list.push({
                    'label': i.name,
                    'value': i.operator_email,
                })
            }
            setOperatorList(list)
        }
    }, [teams])

    return (
        <>
            <CModal visible={visible} setVisible={setVisible} uId="add-notif" title="Add Notification">
                <div className="w-full flex flex-col p-3">
                    <form onSubmit={handelAdd} ref={formRef} className="w-full md:pr-4 pr-0">
                        <div className="w-full flex flex-col">
                            <span className="text-sm mb-2  ml-2 font-bold ">Notification Title</span>
                            <input name="title" type="text" placeholder="Enter title" className="w-full h-[40px] rounded-md border border-hoory-75 bg-hoory-100 px-[10px] transition-all duration-500 focus:outline-none focus:border-pos-100 text-sm" required />
                        </div>
                        <div className="w-full flex flex-col mt-6">
                            <span className="text-sm mb-2  ml-2 font-bold ">Notification Recievers</span>
                            <MultiSelect
                                options={operatorList}
                                value={recieverList}
                                onChange={setRecieverList}
                                labelledBy="Select"
                                className="focus:outline-none focus:border-pos-100"
                            />
                        </div>
                        <div className="w-full flex flex-col mt-6">
                            <span className="text-sm mb-2  ml-2 font-bold ">Notification Description</span>
                            <textarea name="description" placeholder="Enter description" className="w-full min-h-[100px] rounded-md border border-hoory-75 bg-hoory-100 p-[10px] transition-all duration-500 focus:outline-none focus:border-pos-100 text-sm" required />
                        </div>
                        {isLoading ?
                            <button type={'button'} className="w-full h-[40px] bg-hoory-200 text-white text-sm mt-5 rounded-md flex items-center justify-center relative"><BtnLoader /></button>
                            :
                            <button type="submit" className="w-full h-[40px] bg-hoory-200 text-white text-sm mt-5 rounded-md">Submit</button>
                        }
                    </form>
                </div>
            </CModal>
        </>
    )
}