import { CModal } from "components/widget";
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

export const Helper = () => {
    const location = useLocation()
    const { pathname } = location;
    const [visible, setVisible] = useState<boolean>(false)

    useEffect(() => {
        const timer = setTimeout(() => {
            let helper_string = localStorage.getItem('helper')
            if (pathname && helper_string) {
                let helper = JSON.parse(String(helper_string))
                if (helper[pathname] == false) {
                    setVisible(true)
                    helper[pathname] = true;
                    localStorage.setItem('helper', JSON.stringify(helper))
                }
            }
        }, 2000);
        return () => clearTimeout(timer);
    }, [pathname])

    return (
        <>
            <CModal visible={visible} setVisible={setVisible} uId="helper" title="Help">
                <div className="w-full text-justify flex flex-col p-3">
                    <span>{text[pathname]}</span>
                    <button onClick={()=>{setVisible(false)}} className="w-[120px] h-[35px] rounded border border-hoory-200 text-hoory-200 mt-4">Close</button>
                </div>
            </CModal>
        </>
    )
}



let text:any = {
    '/p': 'Here s the Conversation Page in this page you can manage and see how AI is interacting with your customers and if you want you can even take control over and talk to customers yourself.',
    '/p/bots': 'AI Page, this Page you can create, modify, and add data To your AI and after creating your AI you can use that wherever you want.',
    '/p/teams': 'Here s The Teams Page On this page you can add and manage people or operators in your team to interact with people.',
    '/p/reports': 'Here s The Reports Page On this page you can have an overview of what is happening in your panel, how your AI performs, and control the trafics and agents to manage the team better.',
    '/p/notification': 'And now we are on the Notification page On this page you can submit notifications, TODOs, and other things and define tasks for operators and people in your team.',
}
