import { BtnLoader } from 'components/widget';
import { useListMessage } from 'hooks';
import { CSvgLogo, CSvgUser } from 'icon';
import BotImg from 'media/logo/logo.png'
import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ChatStore } from 'store'
import { ChatStepThreeContTypes } from 'types';
import { methodHandelListMessage } from 'utils';


export const ChatStepThreeCont = ({ setStepChat }: ChatStepThreeContTypes) => {
  const [searchParams] = useSearchParams();
  const { clientChat, setClientChat, clientChatPagination, setClientChatPagination } = ChatStore()

  const { isLoading, mutate } = useListMessage(
    setStepChat,
    setClientChatPagination,
    setClientChat,
    clientChat,
    clientChat.length == 0 ? 1 : clientChatPagination?.current + 1
  )

  const loadMoreMessage = () => {
    let room = JSON.parse(String(localStorage.getItem('room_info')))
    methodHandelListMessage(room?.chat_room_id, clientChat.length == 0 ? 1 : clientChatPagination?.current + 1, mutate)
  }

  useEffect(() => {
    if (localStorage.getItem('client_email') && clientChat.length == 0) {
      loadMoreMessage()
    }
  }, [])

  return (
    <div id="chat-box-external" className="w-full md:h-[calc(100%-135px)] h-[calc(100%-125px)] pt-[15px]  bg-hoory-100 overflow-y-auto px-[20px]">
      <div className='w-full flex justify-center'>
        {clientChatPagination?.total > clientChatPagination?.current &&
          <>
            {isLoading ?
              <button className=' h-[30px] w-[100px] mx-auto bg-hoory-200 text-xs text-hoory-175 rounded-full flex items-center justify-center relative '><BtnLoader /></button>
              :
              <button onClick={loadMoreMessage} className=' h-[30px] w-[100px] mx-auto bg-hoory-200 text-xs text-hoory-175 rounded-full '>Load more...</button>
            }
          </>
        }
      </div>
      {clientChat?.map((item: any, index: number) =>
        <div key={index} className={`w-full md:my-5 my-10 flex  ${item?.type == 'client' ? 'justify-end' : 'justify-start'} `}>
          <div className="md:w-[50%] w-[90%] max-w-[500px] relative flex flex-col">
            <div className={`w-[30px] h-[30px] absolute rounded-full overflow-hidden bg-hoory-200 top-[-25px] flex items-center justify-center ${item?.type == 'client' ? 'right-[20px]' : 'left-[20px]'}`}>
              {item?.type == 'client' ?
                <CSvgUser className="w-[23px] stroke-white" />
                :
                <CSvgLogo className="w-[20px] fill-white" />
              }
            </div>
            <div className="w-full p-[15px] bg-hoory-125 rounded-lg flex flex-col mt-[-10px]">
              <div className={`w-full flex ${item?.type == 'client' ? 'justify-end' : 'justify-start'}`}>
                <span className="text-[12px] text-gray-600">{item?.date?.slice(0, 10)}</span>
              </div>
              <span className="mt-2 text-sm leading-6">{item?.message} </span>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}