import { BtnLoader } from "components";
import { useRegister } from "hooks";
import { useRef, useState } from "react";
import { methodHandelRegister } from "utils";
import { CSvgEye, CSvgEyeOff } from "icon";
import { LayoutStore } from "store";


export const RegisterComponents = () => {
    const refForm: any = useRef()
    const {setUserInfo} = LayoutStore()
    const { isLoading, mutate } = useRegister(setUserInfo)
    const [visiblePas, setVisiblepas] = useState<boolean>(false)


    const handelRegister = (e: any) => {
        e.preventDefault();
        methodHandelRegister(refForm, mutate)
    }


    return (
        <>
            <form ref={refForm} onSubmit={handelRegister} className={`w-full flex flex-col pt-[20px]  `}>
                <div className="w-full flex flex-col">
                    <span className="text-xs mb-2 text-gray-500 ml-2 ">Name</span>
                    <input name='name' type="text" placeholder="Enter your name " className="w-full h-[40px] rounded-full border border-hoory-75 bg-transparent px-[10px] focus:outline-none text-sm" required />
                </div>
                <div className="w-full flex flex-col mt-5">
                    <span className="text-xs mb-2 text-gray-500 ml-2 ">Family</span>
                    <input name='family' type="text" placeholder="Enter your family " className="w-full h-[40px] rounded-full border border-hoory-75  bg-transparent px-[10px] focus:outline-none text-sm" required />
                </div>
                <div className="w-full flex flex-col mt-5">
                    <span className="text-xs mb-2 text-gray-500 ml-2 ">Email Address</span>
                    <input name='email' type="text" placeholder="Enter your email " className="w-full h-[40px] rounded-full border border-hoory-75  bg-transparent px-[10px] focus:outline-none text-sm" required />
                </div>
                <div className="w-full flex flex-col mt-5">
                    <span className="text-xs mb-2 text-gray-500 ml-2 ">Password</span>
                    <div className="w-full flex items-center overflow-hidden rounded-full border border-hoory-75  ">
                        <input name='password' type={visiblePas ? 'text' : 'password'} placeholder="min.8 char  " className="w-full h-[40px] bg-transparent px-[10px] focus:outline-none text-sm" required />
                        <div onClick={() => { setVisiblepas(!visiblePas) }} className="w-[40px] h-[40px] cursor-pointer flex items-center justify-center">
                            {visiblePas ?
                                <CSvgEyeOff className="fill-gray-400 w-[20px]" />
                                :
                                <CSvgEye className="fill-gray-400 w-[20px]" />
                            }
                        </div>
                    </div>
                </div>

                {isLoading ?
                    <button type="button" className="w-full h-[40px] bg-hoory-200 text-white text-sm rounded-full mt-10 flex items-center justify-center relative"><BtnLoader /></button>
                    :
                    <button type="submit" className="w-full h-[40px] bg-hoory-200 text-white text-sm rounded-full mt-10">Submit</button>
                }
            </form>
        </>
    )
}