import { BtnLoader } from "components/widget"
import { CModal } from "components"
import { useAddTeam } from "hooks"
import { useRef } from "react"
import { ModalAddTeamTypes } from "types"
import 'react-quill/dist/quill.snow.css';

export const ModalAddTeams = ({ setVisible, visible }: ModalAddTeamTypes) => {
    const formRef: any = useRef()

    const { isLoading, mutate } = useAddTeam(setVisible , formRef)

    const handelAddTeam = (e: any) => {
        e.preventDefault()
        mutate()
    }

    return (
        <>
            <CModal visible={visible} setVisible={setVisible} uId="add-teams" title="Add Team">
                <div className="w-full flex flex-col p-3">
                    <form onSubmit={handelAddTeam} ref={formRef} className="w-full md:pr-4 pr-0">
                        <div className="w-full flex flex-col">
                            <span className="text-sm mb-2  ml-2 font-bold ">Operator email</span>
                            <input name="operator" type="email" placeholder="Enter Operator email" className="w-full h-[40px] rounded-md border border-hoory-75 bg-hoory-100 px-[10px] transition-all duration-500 focus:outline-none focus:border-pos-100 text-sm" required />
                        </div>
                        {isLoading ?
                            <button type={'button'} className="w-full h-[40px] bg-hoory-200 text-white text-sm mt-5 rounded-md flex items-center justify-center relative"><BtnLoader /></button>
                            :
                            <button type="submit" className="w-full h-[40px] bg-hoory-200 text-white text-sm mt-5 rounded-md">Submit</button>
                        }
                    </form>
                </div>
            </CModal>
        </>
    )
}