import { GetConfigApi, PostConfigApi } from "api";
import { useMutation, useQuery } from "react-query"
import { toast } from 'react-toastify';
import { ConfigStoreTypes } from "types";

export const useGetConfig = (
  isActive: boolean,
  setConfig: any,
  handelChnageMode:(mode:string)=>void ,
) => {
  return useQuery("theme", GetConfigApi, {
    refetchOnWindowFocus: false,
    enabled: isActive,
    onSuccess: (data) => {
      localStorage.setItem('config', JSON.stringify(data.data.data[0]))
      setConfig(data.data.data?.[0])
      handelChnageMode(data?.data?.data?.[0]?.mode ? data?.data?.data?.[0]?.mode : 'light')
    }
  })
}

export const useConfig = (
  config: ConfigStoreTypes,
  setConfig: (data: ConfigStoreTypes) => void,
  handelChnageMode: (str: string) => void,
) => {
  return useMutation({
    mutationFn: PostConfigApi,
    onSuccess: res => {
      if (res.data.status) {
        localStorage.setItem('config', JSON.stringify(res.data.data))
        setConfig(res.data.data)
        if (res.postData?.isMode == 1) {
          handelChnageMode(res.postData?.mode)
        }

      }
      else {
        toast(res.data.message, { type: "error" })
      }
    },
    onError: err => {
      console.log(err);
    }
  })
}
