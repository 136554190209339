import { BtnLoader } from 'components/widget'
import { useListMessage, useStartMessage } from 'hooks'
import { CSvgLogo } from 'icon'
import BotImg from 'media/chat/bot.png'
import { useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { ChatStore } from 'store'
import { ChatStepTwoContTypes } from 'types'
import { methodHandelListMessage, methodHandelStartMessage } from 'utils'


export const ChatStepTwoCont = ({ setStepChat }: ChatStepTwoContTypes) => {
  const refForm: any = useRef()
  const [searchParams] = useSearchParams();
  const { clientChat, setClientChat, setClientChatPagination } = ChatStore()

  const { isLoading, mutate } = useStartMessage(setStepChat)

  const handelStartMessage = (e: any) => {
    e.preventDefault()
    let botToken = searchParams.get('token');
    let email = refForm.current['email'].value;
    methodHandelStartMessage(email, botToken, mutate)
  }

  return (
    <div className="w-full h-[calc(100%-57px)] pt-[15px]  bg-hoory-100 overflow-y-auto px-[20px]">
      {[
        'I am here to answer your questions about PixelyAI. So tell me, how can I help you today?',
        'Give the team a way to reach you.'
      ].map((item: any, index: number) =>
        <div className={`w-full mt-10 flex  justify-start `}>
          <div className="md:w-[50%] w-[90%] max-w-[500px] relative flex flex-col">
            <div className={`w-[30px] h-[30px] absolute rounded-full overflow-hidden flex items-center justify-center bg-hoory-200 top-[-25px] left-[20px]`}>
              <CSvgLogo className="w-[20px] fill-white" />
            </div>
            <div className="w-full p-[15px] bg-hoory-125 rounded-lg flex flex-col mt-[-10px]">
              <div className={`w-full flex `}>
              </div>
              <span className="mt-2 text-sm leading-6">{item} </span>
            </div>
          </div>
        </div>
      )}

      <div className={`w-full  mt-10 flex  justify-start `}>
        <div className="md:w-[50%] w-[90%] max-w-[500px] relative flex flex-col">
          <div className={`w-[30px] h-[30px] absolute rounded-full overflow-hidden bg-hoory-200 flex items-center justify-center top-[-25px] left-[20px]`}>
            <CSvgLogo className="w-[20px] fill-white" />
          </div>
          <div className="w-full p-[15px] bg-hoory-125 rounded-lg flex flex-col mt-[-10px]">
            <div className={`w-full flex `}>
            </div>
            <span className="mt-2 text-sm leading-6">Get notified by email </span>
            <form ref={refForm} onSubmit={handelStartMessage} className='w-full flex items-center mt-2 border border-hoory-75 rounded-md  overflow-hidden'>
              <input name='email' placeholder='Please enter your email' type='email' className='w-[calc(100%-35px)] h-[35px] text-xs px-2 bg-transparent border-none focus:outline-none' required />
              {isLoading ?
                <button type='button' className='w-[35px] h-[35px] bg-hoory-200 text-white relative flex items-center justify-center'><BtnLoader /></button>
                :
                <button type='submit' className='w-[35px] h-[35px] bg-hoory-200 text-white'>{">"}</button>
              }
            </form>
          </div>
        </div>
      </div>

    </div>
  )
}