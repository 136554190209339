
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { BaseLayout, ProfileDrower, ProtectedRoute } from 'components'
import { Auth, Chat } from "pages";
import { QueryClientProvider, QueryClient } from 'react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ReactQueryDevtools } from 'react-query/devtools'
import { LayoutStore } from "store";
import VoiceRecorder from "pages/VoiceRecorder";

function App() {
  const queryClient = new QueryClient();
  const { config:conf } = LayoutStore()

  return (
    <>
      <  QueryClientProvider client={queryClient}>
        <ProfileDrower />
        <Routes>
          <Route path="/" element={<Auth />} />
          <Route path="/voice" element={<VoiceRecorder />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/p/*" element={<ProtectedRoute><BaseLayout /></ProtectedRoute>} />
        </Routes>
        {/* <ReactQueryDevtools initialIsOpen={true} /> */}
      </ QueryClientProvider>
      <ToastContainer
        position="top-right"
        rtl={true}
        hideProgressBar={false} 
        newestOnTop
        closeOnClick
        theme={conf.mode == 'dark' ? 'dark' : 'light'}
        pauseOnFocusLoss
        draggable 
        pauseOnHover
      />
    </>
  );
}

export default App;

