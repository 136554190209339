import { BtnLoader } from "components/widget"
import {CModal} from "components"
import { useDeleteTeams } from "hooks"
import { ModalDeleteTeamTypes } from "types"

export const ModalDeleteTeams = ({ setVisible, visible, selectItem, setSelectItem }: ModalDeleteTeamTypes) => {

    const { isLoading, mutate } = useDeleteTeams(selectItem?.operator_email, setVisible)

    const handelDeleteTeams = () => {
        mutate()
    }

    return (
        <>
            <CModal onScap={() => { setSelectItem(null) }} visible={visible} setVisible={setVisible} uId="delete-teams" title="Delete Teams">
                <div className="w-full flex flex-col p-3">
                    <div className="w-full md:pr-4 pr-0">
                        <span>Do you want to delete this item?</span>
                        <div className="w-full flex items-center">
                            {isLoading ?
                                <button className="w-[120px] h-[40px] bg-hoory-200 text-white text-sm mt-5 rounded-md flex items-center justify-center relative"><BtnLoader /></button>
                                :
                                <button onClick={handelDeleteTeams} className="w-[120px] h-[40px] bg-hoory-200 text-white text-sm mt-5 rounded-md">Delete</button>
                            }
                            <button onClick={()=>{setVisible(false);setSelectItem(null)}} className="w-[120px] h-[40px] mx-2 border border-hoory-200 text-hoory-200 text-sm mt-5 rounded-md">Cansel</button>
                        </div>
                    </div>
                </div>
            </CModal>
        </>
    )
}