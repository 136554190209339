export const methodHandelAddBots = (
    refForm: any,
    mutate: (obj: any) => void,
) => {
    let postData = {
        'name': refForm.current['name'].value,
        'description': refForm.current['description'].value,
    }
    mutate(postData)
}


export const methodHandelEditBots = (
    formRef: any,
    mutate: (obj: any) => void,
) => {
    let postData = {
        'name': formRef.current['name'].value,
        'description': formRef.current['description'].value,
    }
    mutate(postData)
}


export const methodHandelAddTrains = (
    formRef: any,
    editorText: any,
    mutate: (obj: any) => void,
) => {
    let postData = {
        'name': formRef.current['name'].value,
        'description': editorText,
    }
    mutate(postData)
}


export const methodHandelEditTrains = (
    formRef: any,
    editorText:any ,
    mutate: (obj: any) => void,
) => {
    let postData = {
        'name': formRef.current['name'].value,
        'description': editorText,
    }
    mutate(postData)
}
