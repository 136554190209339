import {  GetConfigByBotIDApi } from "api";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { LayoutStore } from "store";
import { ChatStepOneBottomTypes } from "types"
import { handelChnageMode } from "utils";

export const ChatStepOneBottom = ({ setStepChat }: ChatStepOneBottomTypes) => {
  const [searchParams] = useSearchParams();
  const { config, setConfig } = LayoutStore()

  const handelStartChat = () => {
    localStorage.setItem('stepChat', '2')
    let token = searchParams.get('token');
    if (token) {
      setStepChat('2')
    }
    else {
      alert("The robot link is not correct")
    }
  }

  const getConfig = async (botToken:any) => {
    if (!localStorage.getItem('config') || localStorage.getItem('config') == null) {
      let res = await GetConfigByBotIDApi(botToken)
      if (res.data.status) {
        setConfig(res.data.data)
        localStorage.setItem('config', JSON.stringify(res.data?.data?.[0]))
        handelChnageMode(res?.data?.data?.[0]?.mode ? res?.data?.data?.[0]?.mode : 'light')
      }
    }
    else {
      setConfig(JSON.parse(String(localStorage.getItem('config'))))
    }
  }

  useEffect(() => {
    let botToken = searchParams.get('token');
    if (botToken) {
      getConfig(botToken)
    }
  }, [])

  return (
    <div className="w-full py-4  md:px-[20px] px-[10px] border-t border-hoory-75 flex flex-col  bg-hoory-100 ">
      <span className="my-2"> We are away at the moment </span>
      <span className="text-xs my-2"> We are away at the moment </span>
      <button onClick={handelStartChat} type="button" className="w-full h-[40px] my-2 bg-hoory-200 text-white text-sm  rounded-md">Start Chat</button>
    </div>
  )
}