import { BtnLoader } from "components/widget"
import { CModal } from "components"
import { useEditTodo } from "hooks"
import { useEffect, useRef } from "react"
import { ModalEditTodoTypes } from "types"
import {  methodHandelCheckList } from "utils"

export const ModalEditTodo = ({ setVisible, visible, selectItem, setSelectItem , check_id }: ModalEditTodoTypes) => {
    const formRef: any = useRef()

    const { isLoading, mutate } = useEditTodo(selectItem?.id,check_id, setVisible)

    const handelEdit = (e: any) => {
        e.preventDefault()
        methodHandelCheckList(formRef, mutate)
    }

    useEffect(() => {
        if (selectItem != null) {
            formRef.current['title'].value = selectItem.title
            formRef.current['deadline'].value = selectItem.deadline?.slice(0, 10)
            formRef.current['description'].value = selectItem.description
        }
    }, [selectItem])

    return (
        <>
            <CModal onScap={() => { setSelectItem(null) }} visible={visible} setVisible={setVisible} uId="edit-todo" title="Edit Todo">
                <div className="w-full flex flex-col p-3">
                    <form onSubmit={handelEdit} ref={formRef} className="w-full md:pr-4 pr-0">
                        <div className="w-full flex flex-col">
                            <span className="text-sm mb-2  ml-2 font-bold ">Todo Title</span>
                            <input name="title" type="text" placeholder="Enter your title " className="w-full h-[40px] rounded-md border border-hoory-75 bg-hoory-100 px-[10px] transition-all duration-500 focus:outline-none focus:border-pos-100 text-sm" required />
                        </div>
                        <div className="w-full flex flex-col mt-6">
                            <span className="text-sm mb-2  ml-2 font-bold ">Deadline</span>
                            <input name="deadline" type="date" placeholder="Enter deadline" className="w-full h-[40px] rounded-md border border-hoory-75 bg-hoory-100 px-[10px] transition-all duration-500 focus:outline-none focus:border-pos-100 text-sm" required />
                        </div>
                        <div className="w-full flex flex-col mt-6">
                            <span className="text-sm mb-2  ml-2 font-bold ">Todo Description</span>
                            <textarea name="description" placeholder="Enter your Description " className="w-full h-[100px] rounded-md border border-hoory-75 bg-hoory-100 p-[10px] transition-all duration-500 focus:outline-none focus:border-pos-100 text-sm" />
                        </div>
                        {isLoading ?
                            <button type={'button'} className="w-full h-[40px] bg-hoory-200 text-white text-sm mt-5 rounded-md flex items-center justify-center relative"><BtnLoader /></button>
                            :
                            <button type="submit" className="w-full h-[40px] bg-hoory-200 text-white text-sm mt-5 rounded-md">Submit Edit</button>
                        }
                    </form>
                </div>
            </CModal>
        </>
    )
}