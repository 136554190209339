export const methodHandelActiveTeams = (
    selectItem: any,
    mutate: (obj: any) => void,
) => {
    let postData = {
        'email':selectItem?.operator_email,
        'active': !selectItem?.active,
    }
    mutate(postData)
}

