import { CModal } from "components/widget"
import { useState } from "react"


export const TrainHelp = () => {
    const [visibleHelp, setVisibleHelp] = useState<boolean>(false)



    return (
        <>
            <div className="w-full text-justify  border border-hoory-200 rounded-md bg-hoory-175 flex flex-col p-2">
                <span className="text-sm leading-6">
                    To use your created AI you have to give him Data so go to the Train Section of your AI and add your files there data should be in Markdown or .md format cause it help AI to undrestand and answer questions better let me show you an example of adding data into AI
                </span>
                <button onClick={() => { setVisibleHelp(true) }} className="mt-2 w-[100px] h-[35px] text-xs bg-hoory-100 rounded border border-hoory-200 text-hoory-200">Read more ...</button>
            </div>

            <CModal visible={visibleHelp} setVisible={setVisibleHelp} className="w-[700px]" uId="help-trains" title="Help">
                <div className="w-full flex flex-col p-3 text-sm">
                    <span>Train Name or File Name </span>
                    <span> Basic Information About Metavers.Ae</span>
                    <span className="mt-2"> Data (Markdown Type)</span>
                    <span className="mt-2"># Who We Are?</span>
                    <span className="mt-2">
                        With several years of experience, a group of young software engineers, graphic artist-designers, and professional 2D and 3D
                        modelers and creative game creators gathered together in a professional team of 25 people to form Almubdieuntech
                        Technology Company in Dubai. A group of young people with new, attractive, and amazing ideas who have a vision of the
                        future and intend to create a revolution in the digital world. This team has started its steps slowly and steadily and
                        is confident in the bright future of its Roadmap to show all its talents and capabilities in the world arena. The
                        The Almubdieuntech Company team has been working in the fields of website design, application, 3D animation, 2D animation,
                        and games for many years. They work day and night, and the members of this team are still increasing to be at the
                        forefront of technology changes. We are with you in Metavers. AE. You are making money. The members of the Almubdieuntech
                        team have exhibited a combination of technology and art in Metavers.AE so that you can live in the future today. I will
                        introduce you to the team members. Thank you for being with us.
                    </span>
                    <span className="mt-2">
                        Almubdieuntech Technology Company in Dubai is a digital platform design, animation, and game development company that
                        turns the ideas of the digital world into reality. It is a two-dimensional game, and with a combination of art,
                        creativity, and experience that the young members of the Almubdieuntech Technology Company team have, they will
                        accompany you from the beginning of an idea to its launch and implementation. Metavers.AE and Bored Hamster NFT projects
                        follow the future of technology and the digital world and are among the pioneering visions of the world's digital
                        future.
                    </span>
                    <span className="mt-2">## FAQ</span>
                    <span className="mt-2">#### What are Metavers.AE?</span>
                    <span className="mt-2">
                        A metaverse including 5 ecosystems that will affect you greatly. A different metaverse for businesses that want to have
                        a place in an amazing world and act differently.
                    </span>
                    <span className="mt-2">
                        #### Why ALMT?
                    </span>
                    <span className="mt-2">
                        ALMT is the main token of Almubdieuntech. ALMT is the basic tool of our work. You can buy and sell on Metavers.AE by
                        using ALMT.
                    </span>
                    <span className="mt-2">
                        #### What is the Mars ecosystem?
                    </span>
                    <span className="mt-2">
                        Living on Mars has been a dream of mankind for a long time. Marsland is a professionally simulated ecosystem, an
                        ecosystem that makes life on Mars possible for you and takes you to a strange and exciting future. It can be said that
                        Mars Land creates a world of imagination and scientific realities of the future of mankind for you. A place where
                        everyone would like to spend a moment there.
                    </span>
                    <span className="mt-2">
                        #### What is the Under Sea ecosystem?
                    </span>
                    <span className="mt-2">
                        Undersea life reminds us of many stories and movies. The Undersea ecosystem is the manifestation of the thoughts and
                        imaginations of different people over the years. The Undersea ecosystem has shown you an underwater world full of wonder
                        and beauty. A world made for you to feel and enjoy Undersea life with all its beauty.
                    </span>
                    <span className="mt-2">
                        #### What is the Modern Natural ecosystem?
                    </span>
                    <span className="mt-2">
                        Living in pristine and real nature with all its beauty, freshness and greenness means living in nature's ecosystem. An
                        ecosystem with a real sense of nature, peace, and relaxation, which restores the human connection to its origin. A space
                        to enjoy walking in nature away from the stress and excitement of today's life, which will make it difficult for you to
                        distinguish between the virtual world and reality.
                    </span>
                    <span className="mt-2">
                        #### What is the Spaceship ecosystem?
                    </span>
                    <span className="mt-2">
                        The ecosystem of strange and exciting spaceships and spaceships is a world of endless human creativity and imagination,
                        which has become a tangible reality for you at Metavers.AE  Although it is virtual reality, this world is so
                        fantastically and professionally made that you will never get tired of being in such a world.
                    </span>
                </div>
            </CModal>

        </>
    )
}