import { CToggle, ModalAddNotification, ModalDeleteNotification, ModalEditNotification } from "components"
import Table from "components/widget/CTable/Table"
import { useNotification, useChangeStatusNotification } from "hooks"
import { CSvgEdit, CSvgTrash } from "icon"
import { useState } from "react"

export const Notification = () => {
  const [visibleDelete, setVisibleDelete] = useState<boolean>(false)
  const [visibleAdd, setVisibleAdd] = useState<boolean>(false)
  const [visibleEdit, setVisibleEdit] = useState<boolean>(false)
  const [selectItem, setSelectItem] = useState<any>(null)
  const { data: notofication, isLoading } = useNotification()
  const { mutate: mutateStatus } = useChangeStatusNotification(selectItem?.id)



  const col = [
    {
      title: "Title",
      render: (i: any) => (<>{i?.title}</>)
    },
    {
      title: "Description",
      render: (i: any) => (<>{i?.description}</>)
    },
    {
      title: "Date",
      render: (i: any) => (<>{i?.date_created?.slice(0, 10)}</>)
    },
    // {
    //   title: "Status",
    //   render: (i: any) => (
    //     <>
    //       {i.status && <span className="text-green-600">Enable</span>}
    //       {!i.status && <span className="text-[#FFA500]">Disable</span>}
    //     </>
    //   )
    // },
    {
      title: "Edit",
      render: (i: any) => (
        <>
          <button onClick={() => handelStartEdit(i)} className="w-[35px] h-[35px] rounded border border-[#FFA500] text-[#FFA500] flex items-center justify-center">
            <CSvgEdit className="w-[20px] fill-[#FFA500]" />
          </button>
        </>
      )
    },
    {
      title: "Delete",
      render: (i: any) => (
        <>
          <button onClick={() => handelStartDelete(i)} className="w-[35px] h-[35px] rounded border border-[tomato] text-[tomato] flex items-center justify-center">
            <CSvgTrash className="w-[20px] fill-[tomato]" />
          </button>
        </>
      )
    },
    // {
    //   title: "Change Status",
    //   render: (i: any) => (
    //     <>
    //       <CToggle checked={i.status} onChange={()=>handelChangeStatus(i)} />
    //     </>
    //   )
    // },
  ]


  const handelChangeStatus = async(item: any) => {
    await setSelectItem(item)
    let postData:any = {
      'status': !item?.status,
  }
    mutateStatus(postData)
  }


  const handelStartDelete = (item: any) => {
    setSelectItem(item)
    setVisibleDelete(true)
  }

  const handelStartEdit = (item: any) => {
    setSelectItem(item)
    setVisibleEdit(true)
  }

  return (
    <>
      <div className="w-full flex flex-col md:p-[20px] p-[10px] bg-hoory-100  rounded-lg">
        <div className="w-full   flex items-center justify-end mb-4">
          <button onClick={() => { setVisibleAdd(true) }} className="px-3 h-[35px] text-xs rounded-md  bg-hoory-175 text-hoory-200"> New +</button>
        </div>
        <Table columns={col} data={notofication?.data?.data} />
      </div>

      <ModalDeleteNotification
        visible={visibleDelete}
        setVisible={setVisibleDelete}
        selectItem={selectItem}
        setSelectItem={setSelectItem}
      />

      <ModalEditNotification
        visible={visibleEdit}
        setVisible={setVisibleEdit}
        selectItem={selectItem}
        setSelectItem={setSelectItem}
      />

      <ModalAddNotification
        visible={visibleAdd}
        setVisible={setVisibleAdd}
      />
    </>
  )
}