import { BtnLoader } from "components/widget"
import { useAddTrain } from "hooks"
import { useRef, useState } from "react"
import { AddNewTrainTypes } from "types"
import { methodHandelAddTrains } from "utils"
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


export const AddNewTrain = ({ bot_id }: AddNewTrainTypes) => {
    const formRef: any = useRef()
    const [editorText, setEditorValue] = useState('');
    const { isLoading, mutate } = useAddTrain(bot_id)

    const handelAddTrains = (e: any) => {
        e.preventDefault()
        methodHandelAddTrains(formRef, editorText, mutate)
    }


    return (
        <>
            <form id="new-trains-form" onSubmit={handelAddTrains} ref={formRef} className="w-full flex flex-col">
                <div className="w-full flex flex-col">
                    <span className="text-sm mb-2  ml-2 font-bold ">File Name</span>
                    <input name='name' type="text" placeholder="Enter your train name " className="w-full h-[40px] rounded-md border border-hoory-75 bg-hoory-100 px-[10px] transition-all duration-500 focus:outline-none focus:border-pos-100 text-sm" required />
                </div>
                <div className="w-full flex flex-col mt-6">
                    <span className="text-sm mb-2  ml-2 font-bold ">Data</span>
                    <ReactQuill className="trains-editor" theme="snow" value={editorText} onChange={setEditorValue} />
                </div>
                {isLoading ?
                    <button className="w-full h-[40px] bg-hoory-200 text-white text-sm mt-10 rounded-md relative flex items-center justify-center"><BtnLoader /></button>
                    :
                    <button className="w-full h-[40px] bg-hoory-200 text-white text-sm mt-10 rounded-md">Submit</button>
                }
            </form>
        </>
    )
}