import { Nav, Container, SubNav, Helper } from "components"
import { useGetConfig } from "hooks/layout-hooks"
import { useEffect, useState } from "react"
import { LayoutStore } from "store"
import { handelChnageMode } from "utils"

export const BaseLayout = () => {
    const { setConfig , setUserInfo } = LayoutStore()
    const [startGetTheme, setStartGetTheme] = useState(false)
    const { } = useGetConfig(startGetTheme, setConfig, handelChnageMode)

    const getConfig = async () => {
        if (!localStorage.getItem('config') || localStorage.getItem('config') == null) {
            setStartGetTheme(true)
        }
        else {
            setConfig(JSON.parse(String(localStorage.getItem('config'))))
        }
    }

    const getUserInfo = () => {
        if (localStorage.getItem("user")) {
            setUserInfo(JSON.parse(String(localStorage.getItem('user'))))
        }
    }


    useEffect(() => {
        getConfig()
        getUserInfo()
    }, [])

    return (
        <div className='w-full  flex justify-end bg-hoory-125'>
            <>
                <Helper />
                <Nav />
                <SubNav />
                <Container />
            </>
        </div>
    )
}