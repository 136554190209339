
export const BotHelpEmpty = () => {


    return (
        <>
            <div className="w-full text-justify  border border-hoory-200 rounded-md bg-hoory-175 flex flex-col p-2">
                <span className="text-sm leading-6">
                    Opps! looks like you haven't created your AI yet so let me help you.
                    You just have to click on the New AI button and add Name and Description for your AI.
                </span>
            </div>



        </>
    )
}