
import { CSvgEmpty } from "icon";
import React, { useEffect, useState } from "react";

export declare type InputProps = {
    data: any,
    columns: any,
    children?: JSX.Element | JSX.Element[],
}


 const Table = ({ data, columns, children }: InputProps) => {
    const [listData, setListData] = useState<any>([]);

    useEffect(() => {
        if (data) {
            setListData(data)
        }
    }, [data]);

    return (
        <div className="Ctable-parent">
            <table className="Ctable">
                <tr className="Ctable-head">
                    {columns?.map((item: any , index:number) =>
                        <th key={index}>
                            <span>{item?.title}</span>
                        </th>
                    )}
                </tr>
                {listData?.map((item: any, index: number) =>
                    <tr key={index} className="Ctable-row" >
                        {columns?.map((items: any , indexCol:number) =>
                            <td key={indexCol} >
                                {item?.[items?.dataIndex]}
                                {item?.[items?.dataIndex] == undefined && items?.render(item)}
                            </td>
                        )}
                    </tr>
                )}
            </table>
            {listData.length == 0 && 
                <div className="w-full h-[200px] border rounded-md mt-3 text-gray-500 flex flex-col items-center justify-center text-sm">
                    <CSvgEmpty className="w-[50px] fill-gray-500 mb-4" />
                    <span>Your list is empty</span>
                </div>
                }
            {/* {listData.length == 0 &&
                <div className=" w-full h-[200px] rounded-[10px] flex flex-col justify-center items-center bg-white shadow">
                    <svg x="0px" y="0px" viewBox="0 0 502 502" className={`w-[60px] fill-dayan-gray-100 `}>
                        <use xlinkHref="/assetes/svg/find.svg#find" />
                    </svg>
                    <span className="text-sm mt-6">There is no data to display</span>
                </div>
            } */}
            {listData.length > 0 &&
                <div className="Ctable-counter">
                    {children}
                </div>
            }
        </div>
    )
}

export default Table ;
