import { DeleteTeamApi, TeamsApi, AddTeamApi , ActiveTeamApi } from "api";
import { useQueryClient, useQuery, useMutation } from "react-query"
import { toast } from "react-toastify";

export const useTeams = (isActive:boolean) => {
  return useQuery("teams", TeamsApi, {
    refetchOnWindowFocus: false,
    enabled:isActive
  })
}


export const useDeleteTeams = (
  email: any,
  setVisible: (bol: boolean) => void,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: () => DeleteTeamApi(email),
    onSuccess: res => {
      if (res.data.status) {
        toast(res.data.data, { type: "success" })
        setVisible(false)
        queryClient.refetchQueries('teams')
      }
      else {
        toast(res.data.message, { type: "error" })
      }
    },
    onError: err => {
      console.log(err);
    }
  })
}



export const useAddTeam = (
  setVisible: (bol: boolean) => void,
  formRef: any,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: () => AddTeamApi(formRef.current['operator'].value),
    onSuccess: res => {
      if (res.data.status) {
        toast(res.data.message, { type: "success" })
        setVisible(false)
        queryClient.refetchQueries('teams')
      }
      else {
        toast(res.data.message, { type: "error" })
      }
    },
    onError: err => {
      console.log(err);
    }
  })
}


export const useActiveTeams = (
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (postData:any) => ActiveTeamApi(postData),
    onSuccess: res => {
      if (res.data.status) {
        toast(res.data.data, { type: "success" })
        queryClient.refetchQueries('teams')
      }
      else {
        toast(res.data.message, { type: "error" })
      }
    },
    onError: err => {
      console.log(err);
    }
  })
}