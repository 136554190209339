import { CheckListApi, AddCheckListApi, DeleteCheckListApi, EditCheckListApi,AddTagApi,DeleteTagApi, TodoApi, AddTodoApi, EditTodoApi, DeleteTodoApi } from "api";
import { useQueryClient, useQuery, useMutation } from "react-query"
import { toast } from "react-toastify";

export const useCheckList = () => {
  return useQuery("checklist", CheckListApi, {
    refetchOnWindowFocus: false,
  })
}


export const useDeleteCheckList = (
  id: any,
  setVisible: (bol: boolean) => void,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: () => DeleteCheckListApi(id),
    onSuccess: res => {
      if (res.data.status) {
        toast(res.data.data, { type: "success" })
        setVisible(false)
        queryClient.refetchQueries('checklist')
      }
      else {
        toast(res.data.message, { type: "error" })
      }
    },
    onError: err => {
      console.log(err);
    }
  })
}



export const useAddCheckList = (
  setVisible: (bol: boolean) => void,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (postData) => AddCheckListApi(postData),
    onSuccess: res => {
      if (res.data.status) {
        toast(res.data.message, { type: "success" })
        setVisible(false)
        queryClient.refetchQueries('checklist')
      }
      else {
        toast(res.data.message, { type: "error" })
      }
    },
    onError: err => {
      console.log(err);
    }
  })
}




export const useEditCheckList = (
  id: any,
  setVisible: (bol: boolean) => void,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (postData) => EditCheckListApi(postData, id),
    onSuccess: res => {
      if (res.data.status) {
        toast(res.data.message, { type: "success" })
        setVisible(false)
        queryClient.refetchQueries('checklist')
      }
      else {
        toast(res.data.message, { type: "error" })
      }
    },
    onError: err => {
      console.log(err);
    }
  })
}



export const useAllTodo = (
  check_id: any,
) => {
  return useQuery(["todo", check_id], () => TodoApi(check_id), {
    refetchOnWindowFocus: false,
  })
}



export const useAddTodo = (
  setVisible: (bol: boolean) => void,
  check_id: any,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (postData) => AddTodoApi(postData, check_id),
    onSuccess: res => {
      if (res.data.status) {
        toast(res.data.message, { type: "success" })
        setVisible(false)
        queryClient.refetchQueries(['todo', check_id])
      }
      else {
        toast(res.data.message, { type: "error" })
      }
    },
    onError: err => {
      console.log(err);
    }
  })
}



export const useEditTodo = (
  todo_id: any,
  check_id: any,
  setVisible: (bol: boolean) => void,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (postData) => EditTodoApi(postData, check_id, todo_id),
    onSuccess: res => {
      if (res.data.status) {
        toast(res.data.message, { type: "success" })
        setVisible(false)
        queryClient.refetchQueries(['todo', check_id])
      }
      else {
        toast(res.data.message, { type: "error" })
      }
    },
    onError: err => {
      console.log(err);
    }
  })
}



export const useDeleteTodo = (
  todo_id: any,
  check_id: any,
  setVisible: (bol: boolean) => void,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: () => DeleteTodoApi(check_id, todo_id),
    onSuccess: res => {
      if (res.data.status) {
        toast(res.data.data, { type: "success" })
        setVisible(false)
        queryClient.refetchQueries(['todo', check_id])
      }
      else {
        toast(res.data.message, { type: "error" })
      }
    },
    onError: err => {
      console.log(err);
    }
  })
}





export const useAddTag = (
  todo_id: any,
  check_id: any,
  setVisible: (bol: boolean) => void,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (postData) => AddTagApi(postData, check_id, todo_id),
    onSuccess: res => {
      if (res.data.status) {
        toast(res.data.message, { type: "success" })
        setVisible(false)
        queryClient.refetchQueries(['todo', check_id])
      }
      else {
        toast(res.data.message, { type: "error" })
      }
    },
    onError: err => {
      console.log(err);
    }
  })
}




export const useDeleteTag = (
  check_id: any,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (postData:any) => DeleteTagApi( postData?.tag_id ,postData?.todo_id, check_id),
    onSuccess: res => {
      if (res.data.status) {
        toast(res.data.message, { type: "success" })
        queryClient.refetchQueries(['todo', check_id])
      }
      else {
        toast(res.data.message, { type: "error" })
      }
    },
    onError: err => {
      console.log(err);
    }
  })
}