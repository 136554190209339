import { CSvgAgents } from "icon"

export const ChatStepTwoHead = () => {



  return (
    <div className="w-full py-2 flex items-center border-b border-hoory-75 md:px-[20px] px-[10px] bg-hoory-100">
      <div className="md:w-[50px] w-[40px] md:h-[50px] h-[40px] bg-hoory-175 flex items-center justify-center text-hoory-200 rounded-full shadow">
        <CSvgAgents className="fill-hoory-200 w-[25px]" />
      </div>
      <div className="ml-2 flex flex-col items-start">
        <span className="text-sm">PixelyAI</span>
      </div>
    </div>
  )
}