import { subNavData } from "utils"
import { handelOpneOrCloseSubNav } from "../../utils"
import { useLocation, useNavigate } from "react-router-dom"

export const SubNav = () => {
    const location = useLocation()
    const navigate = useNavigate()

    const handelChangeRoute = async (path: string) => {
        navigate(path)
        if (window.innerWidth < 676) {
            handelOpneOrCloseSubNav()
        }
    }

    const handelGenerateComponents = (Name: any, item: any) => {
        return <Name className={`w-[20px] ${item.path == location.pathname ? 'fill-hoory-200' : 'fill-hoory-50'}`} />
    }

    return (
        <>
            <div onClick={handelOpneOrCloseSubNav} id='bgSubNav' className='fixed top-0 bottom-0 left-0 right-full transition-all duration-700 bg-[#00000069] z-[3]'></div>
            <div id='subNav' className='w-[200px] transition-all py-3 px-2 top-0 bottom-0 bg-hoory-100 shadow fixed z-[4] left-[-200px] duration-500 flex flex-col '>
                {subNavData.map((item: any, index: number) =>
                    <>
                        {item.allow_display_item.indexOf(location.pathname) != -1 &&
                            <button key={index} onClick={() => handelChangeRoute(item.path)} className={`w-full h-[35px] rounded-md px-2 flex items-center  mb-1 transition-all cursor-pointer ${item.path == location.pathname ? 'bg-hoory-175 text-hoory-200' : 'text-hoory-50 bg-hoory-100 hover:bg-hoory-125'} `}>
                                {handelGenerateComponents(item.icon, item)}
                                <span className="ml-2 text-sm  ">{item.label}</span>
                            </button>
                        }
                    </>
                )}
            </div>
        </>
    )
}