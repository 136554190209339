import { SkeletonBase } from "./SkeletonBase"

export const SkeletonBotsList = () => {

    return (
        <>
            {[0, 0, 0, 0, 0, 0, 0, 0, 0].map((item: any, index: number) =>
                <div key={index} className="w-full h-[138px] flex justify-between items-center bg-hoory-175  shadow rounded-md mb-4 overflow-hidden relative">
                    <SkeletonBase />

                </div>
            )}
        </>
    )
}