import { baseApi, base_url } from "./baseApi";

export const LoginApi = async (postData: any) => {
    const data = await baseApi({ url: `${base_url}auth/login`, method: "POST" , body:JSON.stringify(postData) })
    return { data };
}

// https://api.optics4less.com/api/my/v2/auth/login

export const RegisterApi = async (postData: any) => {
    const data = await baseApi({ url: `${base_url}auth/register`, method: "POST" , body:JSON.stringify(postData) })
    return { data };
}


export const SendMailApi = async (postData: any) => {
    const data = await baseApi({ url: `${base_url}auth/send-email`, method: "POST" , body:JSON.stringify(postData) })
    return { data };
}


export const ChangePassApi = async (postData: any) => {
    const data = await baseApi({ url: `${base_url}auth/change-password`, method: "POST" , body:JSON.stringify(postData) })
    return { data };
}