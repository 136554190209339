import { useEffect, useState } from "react"
import { ProfileData, methodHandelChangeConfig , handelOpneOrCloseProfileDrower, handelChnageMode } from "utils"
import { LayoutStore } from "store"
import { useNavigate } from "react-router-dom"
import { CSvgArrow } from "icon"
import { useConfig } from "hooks/layout-hooks"


export const ProfileDrower = () => {
  const navigation = useNavigate()
  const { config, setConfig } = LayoutStore()
  const { isLoading, mutate } = useConfig(config , setConfig , handelChnageMode )


  const handelLogout = async () => {
    localStorage.clear()
    await handelOpneOrCloseProfileDrower()
    navigation('/')
  }

  const handelChangeConfig = (key: 'status' | 'mode' | 'color', value: string) => {
    methodHandelChangeConfig(config ,key, value , mutate)
  }

  useEffect(() => {
    let conf = localStorage.getItem('config')
    if (conf) {
      handelChnageMode(JSON.parse(conf).mode)
    }
  }, [])

  return (
    <>
      <div onClick={handelOpneOrCloseProfileDrower} id='profileDrowerBg' className=' top-0 bottom-0 right-0 left-0  bg-[#0004] fixed z-[-1] opacity-0 invisible transition-all duration-300 flex justify-end'>

      </div>
      <div id='profileDrower' className='w-[250px]  bg-hoory-100 shadow rounded-xl top-0 right-0 fixed z-[-1] opacity-0 invisible transition-all duration-300 flex flex-col items-center py-4 px-2'>
        <span className='text-xs text-gray-500'>Set yourself as </span>
        <button type={"button"} onClick={() => { handelChangeConfig('status', '1') }} className={`w-full h-[33px] rounded-md flex items-center px-2 mt-3 text-hoory-50 ${config.status == '1' && 'bg-hoory-175 '}`}>
          <span className='flex w-[10px] h-[10px] rounded-full bg-green-400'></span>
          <span className='text-sm ml-2'>Online</span>
        </button>
        <button type={"button"} onClick={() => { handelChangeConfig('status', '2') }} className={`w-full h-[33px] rounded-md flex items-center px-2 mt-1 text-hoory-50 ${config.status == '2' && 'bg-hoory-175 '}`}>
          <span className='flex w-[10px] h-[10px] rounded-full bg-[gold]'></span>
          <span className='text-sm ml-2'>Busy</span>
        </button>
        <button type={"button"} onClick={() => { handelChangeConfig('status', '3') }} className={`w-full h-[33px] rounded-md flex items-center px-2 mt-1 text-hoory-50 ${config.status == '3' && 'bg-hoory-175 '}`}>
          <span className='flex w-[10px] h-[10px] rounded-full bg-[tomato]'></span>
          <span className='text-sm ml-2'>Offline</span>
        </button>

        <div className='w-full border-t border-hoory-75 mt-2 flex justify-center pt-2'>
          <div className='w-[200px] p-[3px] rounded-md border border-hoory-75 flex items-center text-sm'>
            <button id='btnLight' onClick={() => handelChangeConfig('mode', 'light')} className={`w-full h-[35px] transition-all rounded-md ${config?.mode == 'light' ? 'bg-hoory-200 text-white' : 'bg-hoory-100 text-hoory-50'} `}>Light Mode</button>
            <button id='btnDark' onClick={() => handelChangeConfig('mode', 'dark')} className={`w-full h-[35px] transition-all rounded-md ${config?.mode == 'dark' ? 'bg-hoory-200 text-white' : 'bg-hoory-100 text-hoory-50'} `}>Dark Mode</button>
          </div>
        </div>

        <div className='w-full border-t border-hoory-75 flex flex-col mt-2 pt-3 text-hoory-50'>
          {ProfileData.map((item: any, index: number) =>
            <button key={index} type={"button"} className='w-full h-[40px] rounded-md  bg-hoory-100 transition-all flex items-center px-2 text-sm hover:bg-hoory-125'>
              {item.icon}
              <span className='ml-2'>{item.label}</span>
            </button>
          )}
          <button onClick={handelLogout} type={"button"} className='w-full h-[40px] rounded-md  bg-hoory-100 transition-all flex items-center px-2 text-sm hover:bg-hoory-125'>
            <CSvgArrow className="w-[23px] fill-hoory-50" />
            <span className='ml-2'>{'Logout'}</span>
          </button>
        </div>

      </div >
    </>
  )
}



