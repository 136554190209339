import { AddBotsApi, BotsApi, EditBotApi , DeleteBotApi, TrainsApi, AddTrainsApi, EditTrainsApi, DeleteTrainsApi } from "api";
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const useAllBots = () => {
  return useQuery("bots", BotsApi, {
    refetchOnWindowFocus: false,
  })
}

export const useAddBot = () => {
  const navigate = useNavigate()
  return useMutation({
    mutationFn: AddBotsApi,
    onSuccess: res => {
      if (res.data.status) {
        toast(res.data.message, { type: "success" })
        navigate('/p/bots')
      }
      else {
        toast(res.data.message, { type: "error" })
      }
    },
    onError: err => {
      console.log(err);
    }
  })
}



export const useEditBot = (
  bot_id: any,
  setVisible: (bol: boolean) => void ,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (postData) => EditBotApi(postData, bot_id),
    onSuccess: res => {
      if (res.data.status) {
        toast('Bot updated succesfuly.', { type: "success" })
        setVisible(false)
        queryClient.refetchQueries('bots')
      }
      else {
        toast(res.data.message, { type: "error" })
      }
    },
    onError: err => {
      console.log(err);
    }
  })
}



export const useDeleteBot = (
  bot_id: any,
  setVisible: (bol: boolean) => void ,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: () => DeleteBotApi(bot_id),
    onSuccess: res => {
      if (res.data.status) {
        toast(res.data.data, { type: "success" })
        setVisible(false)
        queryClient.refetchQueries('bots')
      }
      else {
        toast(res.data.message, { type: "error" })
      }
    },
    onError: err => {
      console.log(err);
    }
  })
}



export const useAllTrains = (
  bot_id:any,
) => {
  return useQuery(["trains" , bot_id], ()=>TrainsApi(bot_id), {
    refetchOnWindowFocus: false,
  })
}



export const useAddTrain = (
  bot_id:any ,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (postData) => AddTrainsApi(postData, bot_id),
    onSuccess: res => {
      if (res.data.status) {
        toast(res.data.message, { type: "success" })
        queryClient.refetchQueries(['trains' , bot_id])
        //@ts-ignore
        document.getElementById('new-trains-form')?.reset()
      }
      else {
        toast(res.data.message, { type: "error" })
      }
    },
    onError: err => {
      console.log(err);
    }
  })
}



export const useEditTrain = (
  bot_id: any,
  setVisible: (bol: boolean) => void ,
  trains_id:any ,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (postData) => EditTrainsApi(postData, bot_id , trains_id),
    onSuccess: res => {
      if (res.data.status) {
        toast('Train updated succesfuly.', { type: "success" })
        setVisible(false)
        queryClient.refetchQueries(['trains' , bot_id])
      }
      else {
        toast(res.data.message, { type: "error" })
      }
    },
    onError: err => {
      console.log(err);
    }
  })
}



export const useDeleteTrains = (
  bot_id: any,
  setVisible: (bol: boolean) => void ,
  trains_id:any ,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: () => DeleteTrainsApi(bot_id , trains_id),
    onSuccess: res => {
      if (res.data.status) {
        toast(res.data.message, { type: "success" })
        setVisible(false)
        queryClient.refetchQueries(['trains' , bot_id])
      }
      else {
        toast(res.data.message, { type: "error" })
      }
    },
    onError: err => {
      console.log(err);
    }
  })
}

