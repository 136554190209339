import { BtnLoader } from "components";
import { methodHandelChangePassword } from "../../utils";
import { useRef, useState } from "react";
import { CSvgEye, CSvgEyeOff } from "icon";
import { useChangePass } from "hooks";

export const ChangePasswordComponents = ({ setTypeAuthPage }: any) => {
    const refForm: any = useRef()
    const { isLoading, mutate } = useChangePass(setTypeAuthPage)
    const [visiblePas, setVisiblepas] = useState<boolean>(false)

    const handel = (e: any) => {
        e.preventDefault();
        methodHandelChangePassword(refForm, mutate)
    }

    return (
        <>
            <form ref={refForm} onSubmit={handel} className={`w-full flex flex-col pt-[30px]  `}>
                <div className="w-full min-h-[40px] bg-hoory-125 mb-10 flex items-center px-2 border-l-2 border-hoory-200 text-sm">
                    Enter the code sent to your email with the new password
                </div>
                <div className="w-full flex flex-col">
                    <span className="text-xs mb-2 text-gray-500 ml-2 ">Code</span>
                    <input name='code' type="number" placeholder="Enter code" className="w-full h-[40px] rounded-full border border-hoory-75  bg-transparent px-[10px] focus:outline-none text-sm" required />
                </div>
                <div className="w-full flex flex-col mt-5">
                    <span className="text-xs mb-2 text-gray-500 ml-2 ">New Password</span>
                    <div className="w-full flex items-center overflow-hidden rounded-full border border-hoory-75  ">
                        <input name='password' type={visiblePas ? 'text' : 'password'} placeholder="min.8 char  " className="w-full h-[40px] bg-transparent px-[10px] focus:outline-none text-sm" required />
                        <div onClick={() => { setVisiblepas(!visiblePas) }} className="w-[40px] h-[40px] cursor-pointer flex items-center justify-center">
                            {visiblePas ?
                                <CSvgEyeOff className="fill-gray-400 w-[20px]" />
                                :
                                <CSvgEye className="fill-gray-400 w-[20px]" />
                            }
                        </div>
                    </div>
                </div>
                {isLoading ?
                    <button type="button" className="w-full h-[40px] bg-hoory-200 text-white text-sm rounded-full mt-10 flex items-center justify-center relative"><BtnLoader /></button>
                    :
                    <button type="submit" className="w-full h-[40px] bg-hoory-200 text-white text-sm rounded-full mt-10">Submit</button>
                }
                <div className="w-full flex flex-col mt-10">
                    <div className="w-full relative border-t border-hoory-75  flex justify-center ">
                        <span className="bg-hoory-100 -mt-[14px] flex w-[40px] justify-center">or</span>
                    </div>
                    <div className="w-full flex items-center justify-between mt-4 text-xs px-2">
                        {/* <span className="cursor-pointer">Do you have an account ?</span> */}
                        <span onClick={() => { setTypeAuthPage('login') }} className="text-blue-500 cursor-pointer">login page</span>
                    </div>
                </div>
            </form>
        </>
    )
}