import { GetChatApi , GetAllConvertionApi } from "api";
import { useMutation, useQuery } from "react-query"

export const useAllConvertion = () => {
  return useQuery("convertion", GetAllConvertionApi, {
    refetchOnWindowFocus: false,
  })
}


export const useConvertionMessage = (
  setConvertionPagination: (data: any) => void,
  setConvertion: (data: any) => void,
  convertion: any,
) => {
  return useMutation({
    mutationFn: (postData) => GetChatApi(postData),
    onSuccess: res => {
      if (res.data.status) {
        setConvertion([...res?.data?.data?.message_list, ...convertion,]);
        // setConvertionPagination({
        //   'total': res?.data?.data?.total_page,
        //   'current': page,
        // })
      }
      else {
        // toast(res.data.message, { type: "error" })
      }
    },
    onError: err => {
      console.log(err);
    }
  })
}

