import { baseApi, base_url } from "./baseApi";

export const CheckListApi = async () => {
    const data = await baseApi({ url: `${base_url}checklist`, method: "GET" , token:true })
    return { data };
}


export const AddCheckListApi = async (postData:any) => {
    const data = await baseApi({ url: `${base_url}checklist/create`, method: "POST" , token:true , body:JSON.stringify(postData) })
    return { data };
}


export const DeleteCheckListApi = async (id:any) => {
    const data = await baseApi({ url: `${base_url}checklist/${id}/delete`, method: "DELETE" , token:true })
    return { data };
}



export const EditCheckListApi = async (postData: any , id:number) => {
    const data = await baseApi({ url: `${base_url}checklist/${id}/edit`, method: "PUT" , body:JSON.stringify(postData) , token:true })
    return { data };
}



export const TodoApi = async (check_id:number) => {
    const data = await baseApi({ url: `${base_url}checklist/${check_id}/todo`, method: "GET" , token:true })
    return { data };
}

export const AddTodoApi = async (postData:any , check_id:any) => {    
    const data = await baseApi({ url: `${base_url}checklist/${check_id}/todo/create`, method: "POST" , token:true , body:JSON.stringify(postData) })
    return { data };
}


export const EditTodoApi = async (postData: any , check_id:number , todo_id:number) => {
    const data = await baseApi({ url: `${base_url}checklist/${check_id}/todo/${todo_id}/edit`, method: "PUT" , body:JSON.stringify(postData) , token:true })
    return { data };
}



export const DeleteTodoApi = async (check_id:number , todo_id:number) => {
    const data = await baseApi({ url: `${base_url}checklist/${check_id}/todo/${todo_id}/delete`, method: "DELETE" , token:true })
    return { data };
}





export const AddTagApi = async (postData: any , check_id:number , todo_id:number) => {
    const data = await baseApi({ url: `${base_url}checklist/${check_id}/todo/${todo_id}/tag/create`, method: "POST" , body:JSON.stringify(postData) , token:true })
    return { data };
}




export const DeleteTagApi = async (tag_id:number  , todo_id:number ,check_id:number) => {
    const data = await baseApi({ url: `${base_url}checklist/${check_id}/todo/${todo_id}/tag/${tag_id}/delete`, method: "DELETE"  , token:true })
    return { data };
}
