import { LoginComponents, RegisterComponents, SendMailComponents } from "components"
import { ChangePasswordComponents } from "components/auth-components/ChangePasswordComponents"
import { CSvgMiniLogo } from "icon"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { LayoutStore } from "store"


export const Auth = () => {
    const navigation = useNavigate()
    const { typeAuthPage, setTypeAuthPage } = LayoutStore()


    useEffect(() => {
        let user = localStorage.getItem('user')
        let token = localStorage.getItem('token')
        if (user && token) {
            navigation('/p')
        }
    }, [])

    return (
        <div className="auth w-full md:min-h-screen min-h-fit relative flex justify-center bg-hoory-125 items-center overflow-hidden">
            <div className='w-full max-w-2xl md:h-[600px] h-full  md:shadow shadow-none bg-hoory-100 flex flex-wrap overflow-hidden rounded-md'>
                {/* <div className='w-full md:w-6/12 h-full relative md:flex hidden overflow-hidden '>
                    <span className='absolute left-0 right-0 bottom-0 top-0 bg-[#00000057] z-[2]'></span>
                    <img src={LoginImg} className={`w-full h-full object-cover object-center absolute transition-all duration-700 ${typePage == 'login' ? 'z-[1] top-0 opacity-100' : 'z-[-1] top-[-20px] opacity-0'}`} alt='auth' />
                    <img src={RegisterImg} className={`w-full h-full object-cover object-center absolute transition-all duration-700 ${typePage == 'register' ? 'z-[1] top-0 opacity-100' : 'z-[-1] top-[-20px] opacity-0'}`} alt='auth' />
                </div> */}
                <div className='w-full  h-full p-[20px]'>
                    <div className='w-full flex flex-col m-auto max-w-[400px] '>
                        <div className='w-full flex flex-col items-center justify-center '>
                            <CSvgMiniLogo className="w-[40px] " />
                            {/* <img src={BotImg} className="w-[40px] rounded-[7px]" /> */}
                            <span className='my-2'>Welcome to Pixely Ai</span>
                            {['change_pass', 'send_mail'].indexOf(typeAuthPage) == -1 &&
                                <div className="w-[200px] h-[40px] flex items-center justify-between rounded-full border border-hoory-75 shadow mt-4 p-1">
                                    <button onClick={() => { setTypeAuthPage('login') }} type="button" className={`w-full h-full bg-pos-100 rounded-full flex items-center justify-center transition-all text-xs ${typeAuthPage == 'login' ? 'bg-hoory-200 text-white ' : 'bg-hoory-100 text-pos-25'}`}>Login</button>
                                    <button onClick={() => { setTypeAuthPage('register') }} type="button" className={`w-full h-full bg-pos-100 rounded-full flex items-center justify-center transition-all text-xs ${typeAuthPage == 'register' ? 'bg-hoory-200 text-white ' : 'bg-hoory-100 text-pos-25'}`}>Register</button>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="w-full  flex flex-col relative overflow-hidden">
                        {typeAuthPage == 'login' &&
                            <LoginComponents setTypeAuthPage={setTypeAuthPage} />
                        }
                        {typeAuthPage == 'register' &&
                            <RegisterComponents />
                        }
                        {typeAuthPage == 'send_mail' &&
                            <SendMailComponents setTypeAuthPage={setTypeAuthPage} />
                        }
                        {typeAuthPage == 'change_pass' &&
                            <ChangePasswordComponents setTypeAuthPage={setTypeAuthPage} />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}