import { CSvgEmoji, CSvgAttribute, CSvgLike, CSvgOneOff, CSvgBug, CSvgKeyboard, CSvgProfile, CSvgIntegrations, CSvgChat, CSvgApplication, CSvgAutomatic, CSvgBag, CSvgArrow, CSvgLabels, CSvgAgents, CSvgConversation, CSvgMention, CSvgContacts, CSvgReports, CSvgCampaigns, CSvgBots, CSvgCogs, CSvgPersonalCard, CSvgEmail, CSvgCheckList } from "icon"

const ConversationAllowList = ['/p', '/p/mention', '/p/unattended'];
const ContactsAllowList = ['/p/teams'];
const ReportsAllowList = ['/p/reports', '/p/reports/conversation', '/p/reports/cast', '/p/reports/agents', '/p/reports/labels', '/p/reports/inbox', '/p/reports/team'];
const NotificationAllowList = ['/p/notification', '/p/inbox'];
const CheckListAllowList = ['/p/checklist', '/p/checklist/todo'];
const BotsAllowList = ['/p/bots', '/p/bots/add', '/p/bots/train'];
const SettingsAllowList = ['/p/settings', '/p/settings/inboxes', '/p/settings/labels', '/p/settings/custom_ttribute', '/p/settings/automatic', '/p/settings/canned_response', '/p/settings/integrations', '/p/settings/applications']

export let NavData = [
  {
    'icon': <CSvgConversation className="w-[23px] fill-hoory-50" />,
    'label': 'Conversation',
    'allow_bg_path': ConversationAllowList,
    'path': '/p'
  },
  {
    'icon': <CSvgContacts className="w-[23px] fill-hoory-50" />,
    'label': 'Teams',
    'allow_bg_path': ContactsAllowList,
    'path': 'teams'
  },
  {
    'icon': <CSvgReports className="w-[23px] fill-hoory-50" />,
    'label': 'Reports',
    'allow_bg_path': ReportsAllowList,
    'path': 'reports'
  },
  {
    'icon': <CSvgCampaigns className="w-[23px] fill-hoory-50" />,
    'label': 'Notification',
    'allow_bg_path': NotificationAllowList,
    'path': 'notification'
  },
  {
    'icon': <CSvgBots className="w-[23px] fill-hoory-50" />,
    'label': 'AI',
    'allow_bg_path': BotsAllowList,
    'path': 'bots'
  },
  {
    'icon': <CSvgCheckList className="w-[23px] fill-hoory-50" />,
    'label': 'Check List',
    'allow_bg_path': CheckListAllowList,
    'path': 'checklist'
  },
  // {
  //   'icon': <CSvgCogs className="w-[23px] fill-hoory-50" />,
  //   'label': 'Settings',
  //   'allow_bg_path': SettingsAllowList,
  //   'path': 'settings'
  // },
]


export const subNavData = [
  {
    'icon': CSvgCampaigns,
    'label': 'All Conversation',
    'path': '/p',
    'allow_display_item': ConversationAllowList
  },
  // {
  //   'icon': CSvgMention,
  //   'label': 'Mention',
  //   'path': '/p/mention',
  //   'allow_display_item': ConversationAllowList
  // },
  // {
  //   'icon': CSvgEmail,
  //   'label': 'Unattended',
  //   'path': '/p/unattended',
  //   'allow_display_item': ConversationAllowList
  // },
  // Contacts data
  {
    'icon': CSvgPersonalCard,
    'label': 'Teams',
    'path': '/p/teams',
    'allow_display_item': ContactsAllowList
  },
  // Reports data
  {
    'icon': CSvgReports,
    'label': 'Reports',
    'path': '/p/reports',
    'allow_display_item': ReportsAllowList
  },
  // {
  //   'icon': CSvgConversation,
  //   'label': 'Conversation',
  //   'path': '/p/reports/conversation',
  //   'allow_display_item': ReportsAllowList
  // },
  // {
  //   'icon': CSvgEmoji,
  //   'label': 'CAST',
  //   'path': '/p/reports/cast',
  //   'allow_display_item': ReportsAllowList
  // },
  // {
  //   'icon': CSvgAgents,
  //   'label': 'Agents',
  //   'path': '/p/reports/agents',
  //   'allow_display_item': ReportsAllowList
  // },
  // {
  //   'icon': CSvgLabels,
  //   'label': 'Labels',
  //   'path': '/p/reports/labels',
  //   'allow_display_item': ReportsAllowList
  // },
  // {
  //   'icon': CSvgConversation,
  //   'label': 'Inbox',
  //   'path': '/p/reports/inbox',
  //   'allow_display_item': ReportsAllowList
  // },
  // {
  //   'icon': CSvgContacts,
  //   'label': 'Team',
  //   'path': '/p/reports/team',
  //   'allow_display_item': ReportsAllowList
  // },
  // Campaigns data
  {
    'icon': CSvgArrow,
    'label': 'Ongoing ',
    'path': '/p/notification',
    'allow_display_item': NotificationAllowList,
  },
  {
    'icon': CSvgArrow,
    'label': 'Inbox ',
    'path': '/p/inbox',
    'allow_display_item': NotificationAllowList,
  },
  {
    'icon': CSvgCheckList,
    'label': 'Check List ',
    'path': '/p/checklist',
    'allow_display_item': CheckListAllowList,
  },
  // {
  //   'icon': CSvgOneOff,
  //   'label': 'One off ',
  //   'path': '/p/campaigns/one_off',
  //   'allow_display_item': CampaignsAllowList,
  // },
  // Bots data
  {
    'icon': CSvgBots,
    'label': 'AI ',
    'path': '/p/bots',
    'allow_display_item': BotsAllowList,
  },
  {
    'icon': CSvgBots,
    'label': 'New AI ',
    'path': '/p/bots/add',
    'allow_display_item': BotsAllowList,
  },
  // Settings data
  {
    'icon': CSvgBag,
    'label': 'Account Settings',
    'path': '/p/settings',
    'allow_display_item': SettingsAllowList,
  },
  // {
  //   'icon': CSvgConversation,
  //   'label': 'Inboxes',
  //   'path': '/p/settings/inboxes',
  //   'allow_display_item': SettingsAllowList,
  // },
  // {
  //   'icon': CSvgLabels,
  //   'label': 'Labels',
  //   'path': '/p/settings/labels',
  //   'allow_display_item': SettingsAllowList,
  // },
  // {
  //   'icon': CSvgAttribute,
  //   'label': 'Custom Attribute',
  //   'path': '/p/settings/custom_ttribute',
  //   'allow_display_item': SettingsAllowList,
  // },
  // {
  //   'icon': CSvgAutomatic,
  //   'label': 'Automatic',
  //   'path': '/p/settings/automatic',
  //   'allow_display_item': SettingsAllowList,
  // },
  // {
  //   'icon': CSvgChat,
  //   'label': 'Canned Response',
  //   'path': '/p/settings/canned_response',
  //   'allow_display_item': SettingsAllowList,
  // },
  // {
  //   'icon': CSvgIntegrations,
  //   'label': 'Integrations',
  //   'path': '/p/settings/integrations',
  //   'allow_display_item': SettingsAllowList,
  // },
  // {
  //   'icon': CSvgApplication,
  //   'label': 'Applications',
  //   'path': '/p/settings/applications',
  //   'allow_display_item': SettingsAllowList,
  // },
]




export let ProfileData = [
  {
    'icon': <CSvgPersonalCard className="w-[23px] fill-hoory-50" />,
    'label': 'Billing',
    'path': '/p'
  },
  {
    'icon': <CSvgKeyboard className="w-[23px] fill-hoory-50" />,
    'label': 'Keyboard Shortcuts',
    'path': '/p'
  },
  {
    'icon': <CSvgProfile className="w-[23px] fill-hoory-50" />,
    'label': 'Profile Setting',
    'path': '/p'
  },
  {
    'icon': <CSvgBug className="w-[23px] fill-hoory-50" />,
    'label': 'Report a Bug',
    'path': '/p'
  },
  {
    'icon': <CSvgLike className="w-[23px] fill-hoory-50" />,
    'label': 'Submit Feedback',
    'path': '/p'
  },
  {
    'icon': <CSvgChat className="w-[23px] fill-hoory-50" />,
    'label': 'Feature Request',
    'path': '/p'
  },
]

