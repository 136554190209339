import {CModal} from "components"
import { toast } from "react-toastify"
import { ModalBotsTypes } from "types"

export const ModalDisplayScriptBots = ({ setVisible, visible, selectItem , setSelectItem }: ModalBotsTypes) => {

    const handelCopy = async() => {
        let script = `<script src="https://apppixely.hodev.ir/chat.js" type="text/javascript" id="pixelyai" token="${selectItem?.id}"></script>`
        await navigator.clipboard.writeText(script)
        setVisible(false)
        toast("copyed to clipboard", { type: "success" })
    }

    return (
        <>
            <CModal onScap={()=>{setSelectItem(null)}} visible={visible} setVisible={setVisible} uId="script-bots" title="Copy Bots Script" >
                <div className="w-full flex flex-col p-3">
                    <span className="my-2 font-bold text-[17px]">Script Bot :</span>
                    <span className="text-xs text-gray-600">This Script is required if you are bulding an bot</span>
                    <div className="w-full p-2 text-sm rounded-md border border-hoory-75 bg-hoory-125 mt-3 flex items-center justify-between px-2">
                        <div className="w-[calc(100%-70px)]">
                            {`<script src="https://apppixely.hodev.ir/chat.js" type="text/javascript" id="pixelyai" token="${selectItem?.id}"></script>`}
                        </div>
                        <button onClick={handelCopy} className="w-[50px] h-[25px] rounded-full bg-hoory-200 text-white text-xs">Copy</button>
                    </div>
                </div>
            </CModal>
        </>
    )
}