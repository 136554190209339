import { baseApi, base_url } from "./baseApi";

export const NotificationApi = async () => {
    const data = await baseApi({ url: `${base_url}notification`, method: "GET", token: true })
    return { data };
}


export const DeleteNotificationApi = async (id: any) => {
    const data = await baseApi({ url: `${base_url}notification/${id}/delete`, method: "DELETE", token: true })
    return { data };
}


export const AddNotificationApi = async (postData: any) => {
    const data = await baseApi({ url: `${base_url}notification/create`, method: "POST", token: true, body: JSON.stringify(postData) })
    return { data };
}


export const EditNotificationApi = async (postData: any, id: number) => {
    const data = await baseApi({ url: `${base_url}notification/${id}/update`, method: "PUT", body: JSON.stringify(postData), token: true })
    return { data };
}


export const ChangeStatusNotificationApi = async (postData: any, id: number) => {
    const data = await baseApi({ url: `${base_url}notification/${id}/status`, method: "PUT", body: JSON.stringify(postData), token: true })
    return { data };
}


export const InboxApi = async () => {
    const data = await baseApi({ url: `${base_url}inbox`, method: "GET", token: true })
    return { data };
}


export const AcceptConfirmApi = async (postData: any, id: any) => {
    let data: any;
    if (postData.type == 'accept') {
        data = await baseApi({ url: `${base_url}inbox/${id}/accept-confirmation`, method: "POST", token: true })
    }
    else {
        data = await baseApi({ url: `${base_url}inbox/${id}/deny-confirmation`, method: "POST", token: true })
    }
    return { data };
}