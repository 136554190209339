import { BtnLoader } from "components/widget"
import { CModal } from "components"
import { useConfirmInbox } from "hooks"
import { ModalViewInboxTypes } from "types"

export const ModalViewInbox = ({ setVisible, visible, selectItem, setSelectItem }: ModalViewInboxTypes) => {

    const { isLoading, mutate } = useConfirmInbox(selectItem?.uuid, setVisible)

    const handelConfirm = (type: string) => {
        let postData: any = {
            'type': type
        }
        mutate(postData)
    }

    return (
        <>
            <CModal onScap={() => { setSelectItem(null) }} visible={visible} setVisible={setVisible} uId="view-inbox" title="Detail Inbox">
                <div className="w-full flex flex-col p-3">
                    <div className="w-full md:pr-4 pr-0">
                        {selectItem?.type == 'inbox_confirmation' ?
                            <>
                                <span>{selectItem?.message}</span>
                                <div className="w-full flex items-center">
                                    {isLoading ?
                                        <button className="w-[120px] h-[40px] bg-hoory-200 text-white text-sm mt-5 rounded-md flex items-center justify-center relative"><BtnLoader /></button>
                                        :
                                        <button onClick={() => handelConfirm('accept')} className="w-[120px] h-[40px] bg-hoory-200 text-white text-sm mt-5 rounded-md">Accept</button>
                                    }
                                    {isLoading ?
                                        <button className="w-[120px] h-[40px] mx-2 border border-hoory-200 text-hoory-200 text-sm mt-5 rounded-md flex items-center justify-center relative"><BtnLoader /></button>

                                        :
                                        <button onClick={() => handelConfirm('deny')} className="w-[120px] h-[40px] mx-2 border border-hoory-200 text-hoory-200 text-sm mt-5 rounded-md">Deny</button>
                                    }
                                </div>
                            </>
                            :
                            <span>{selectItem?.description}</span>
                        }
                    </div>
                </div>
            </CModal>
        </>
    )
}