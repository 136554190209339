import React, { useEffect, useRef, useState } from 'react';

const VoiceRecorder: React.FC = () => {
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [analyser, setAnalyser] = useState<AnalyserNode | null>(null);
  const [dataArray, setDataArray] = useState<Uint8Array | null>(null);
  const [bufferLength, setBufferLength] = useState<number | null>(null);
  const [microphone, setMicrophone] = useState<MediaStreamAudioSourceNode | null>(null);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
  const [webSocket, setWebSocket] = useState<WebSocket | null>(null);
  let animationId: any;
  let previousHeights: any = [];

  const connectToWebSocketVoice = () => {
    const ws = new WebSocket('wss://api.pixelyai.com/ws/chat/99044815-10f1-415b-ba56-7deac00db61f/client/user1863863@gmail.com/');

    ws.onopen = () => {
      console.log('WebSocket connected');
    };

    ws.onmessage = (message: MessageEvent) => {
      console.log('Message from server: ', message.data);
    };

    ws.onerror = (error: Event) => {
      console.error('WebSocket error: ', error);
    };

    ws.onclose = () => {
      console.log('WebSocket closed');
      setTimeout(connectToWebSocketVoice, 3000);
    };

    setWebSocket(ws);
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
      const analyserNode = audioContext.createAnalyser();
      analyserNode.fftSize = 2048;

      const bufferLengthValue = analyserNode.frequencyBinCount;
      const dataArrayValue = new Uint8Array(bufferLengthValue);

      const microphoneSource = audioContext.createMediaStreamSource(stream);
      microphoneSource.connect(analyserNode);

      const recorder = new MediaRecorder(stream);

      recorder.ondataavailable = (event: BlobEvent) => {
        if (event.data.size > 0 && webSocket && webSocket.readyState === WebSocket.OPEN) {
          webSocket.send(event.data);
        }
      };

      recorder.start(1000);

      setAnalyser(analyserNode);
      setDataArray(dataArrayValue);
      setBufferLength(bufferLengthValue);
      setMicrophone(microphoneSource);
      setMediaRecorder(recorder);
      setIsRecording(true);

      drawVisualizer(analyserNode, dataArrayValue, bufferLengthValue);
    } catch (error) {
      console.error('Error starting recording:', error);
    }
  };

  const stopRecording = () => {
    if (microphone) {
      microphone.disconnect();
      microphone.mediaStream.getTracks().forEach((track) => track.stop());
    }

    if (mediaRecorder) {
      mediaRecorder.stop();
    }

    if (webSocket) {
      webSocket.close();
    }

    if (animationId) {
      cancelAnimationFrame(animationId);
    }

    setIsRecording(false);
    setAnalyser(null);
    setDataArray(null);
    setBufferLength(null);
    setMicrophone(null);
    setMediaRecorder(null);
    setWebSocket(null);
  };

  const drawVisualizer = (analyser: any, dataArray: any, bufferLength: any) => {
    const canvas:any = canvasRef.current;
    const canvasCtx = canvas?.getContext('2d');
    const WIDTH = canvas.width;
    const HEIGHT = canvas.height;
    const MID_Y = HEIGHT / 2; // خط میانی

    // درخواست فریم جدید
    animationId = requestAnimationFrame(() => drawVisualizer( analyser, dataArray, bufferLength));

    // دریافت داده‌های صوتی
    analyser.getByteFrequencyData(dataArray);

    // پاک کردن canvas برای رسم جدید (پس‌زمینه شفاف)
    canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);

    // محاسبه عرض میله‌ها
    const barWidth = (WIDTH / bufferLength) * 3;
    let x = 0;

    // تنظیم سایه
    canvasCtx.shadowBlur = 20; // مقدار محوشدگی
    canvasCtx.shadowColor = '#3333ff'; // رنگ سایه

    // ایجاد آرایه اولیه برای ارتفاع‌های قبلی
    if (previousHeights.length === 0) {
      previousHeights = new Array(bufferLength).fill(0);
    }

    // رسم منحنی به جای میله‌ها
    canvasCtx.beginPath();
    for (let i = 0; i < bufferLength; i++) {
      const currentHeight = dataArray[i] / 2.5; // ارتفاع میله فعلی
      const smoothHeight = (previousHeights[i] * 0.9) + (currentHeight * 0.1); // میانگین‌گیری تدریجی برای نرم کردن

      previousHeights[i] = smoothHeight; // ذخیره ارتفاع جدید برای دفعات بعد

      const y = MID_Y - smoothHeight; // محاسبه ارتفاع نرم شده از وسط

      // تعیین رنگ بر اساس ارتفاع موج
      if (smoothHeight > 50) {
        canvasCtx.strokeStyle = '#ff00ff'; // رنگ سفید برای ارتفاع زیاد
      } else {
        canvasCtx.strokeStyle = '#ffccff'; // رنگ بنفش برای ارتفاع کم
      }

      // نقاط منحنی
      const xPos = x + barWidth * i; // موقعیت افقی
      canvasCtx.lineTo(xPos, y); // رسم خط به موقعیت جدید
      canvasCtx.lineTo(xPos, MID_Y + smoothHeight); // رسم خط به پایین
    }
    canvasCtx.lineTo(WIDTH, HEIGHT); // به پایین canvas
    canvasCtx.lineTo(0, HEIGHT); // به پایین سمت چپ
    canvasCtx.closePath();
    canvasCtx.fill(); // پر کردن رنگ با محوشدگی

    canvasCtx.stroke(); // رسم خطوط
  };

  useEffect(() => {
    connectToWebSocketVoice();

    return () => {
      if (isRecording) {
        stopRecording();
      }
    };
  }, []);

  return (
    <div className="max-w-2xl min-h-screen mx-auto flex items-center justify-center flex-col text-white bg-black">
      <button className='h-[40px] bg-blue-600 mb-5 px-5' onClick={startRecording} disabled={isRecording}>
        Start Recording
      </button>
      <button className='h-[40px] bg-blue-600 mb-5 px-5' onClick={stopRecording} disabled={!isRecording}>
        Stop Recording
      </button>
      <canvas ref={canvasRef} className='canvas' width="300" height="150"></canvas>
    </div>
  );
};

export default VoiceRecorder;
