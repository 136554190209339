import { CModal } from "components"
import { ModalDetailTodoTypes } from "types"

export const ModalDetailTodo = ({ setVisible, visible, selectItem, setSelectItem }: ModalDetailTodoTypes) => {


    return (
        <>
            <CModal onScap={() => { setSelectItem(null) }} visible={visible} setVisible={setVisible} uId="detail-todo" title={selectItem?.title}>
                <div className="w-full flex flex-col p-3">
                    <span className="text-justify">{selectItem?.description}</span>
                    <div className="flex flex-col">
                        <span className="text-base mt-4">Tags :</span>
                        <div className="w-full flex items-center flex-wrap mt-2">
                            {selectItem?.tags.map((tag: any, ind: number) =>
                                <div key={ind} className="h-[25px] px-2 mx-2 mb-2 rounded bg-hoory-75 text-hoory-25 text-xs flex items-center justify-center">{tag.name}</div>
                            )}
                        </div>
                    </div>
                    <div className="flex items-center mt-4">
                        <span className="text-base ">Deadline :</span>
                        <div className="w-fit px-2 rounded  flex items-center flex-wrap  text-base border ml-2">
                            {selectItem?.deadline?.slice(0, 10)}
                        </div>
                    </div>
                    <div className="flex items-center mt-4">
                        <button onClick={()=>{setVisible(false)}} className="w-[100px] h-[40px] rounded border border-hoory-200 text-hoory-200 ">Close</button>
                    </div>
                </div>
            </CModal>
        </>
    )
}