import { ChatStepThreeHead, ChatStepThreeForm, ChatStepThreeCont, ChatStepOneHead, ChatStepOneBottom, ChatStepTwoHead, ChatStepTwoCont } from "components"
import { useState, useEffect } from "react"
import { useLocation, useSearchParams } from "react-router-dom";

export const Chat = () => {
    const [stepChat, setStepChat] = useState<any>('1')
    const [searchParams] = useSearchParams();
    const location = useLocation()

    useEffect(() => {
        let token = searchParams.get('token');
        if (token) {
            let step = localStorage.getItem('stepChat')
            if (step == null || !token) {
                localStorage.setItem('stepChat', '1')
            }
            else {
                setStepChat(localStorage.getItem('stepChat'))
            }
        }
        else {
            localStorage.setItem('stepChat', '1')
        }
    }, [location])

    return (
        <div style={{height:'100dvh'}} className="w-full flex flex-col justify-between bg-hoory-125 ">
            {stepChat == 0 &&
                <>
                    <div className="m-auto">The robot link is not correct</div>
                </>
            }
            {stepChat == 1 &&
                <>
                    <ChatStepOneHead />
                    <ChatStepOneBottom setStepChat={setStepChat} />
                </>
            }

            {stepChat == 2 &&
                <>
                    <ChatStepTwoHead />
                    <ChatStepTwoCont setStepChat={setStepChat} />
                </>
            }

            {stepChat == 3 &&
                <>
                    <ChatStepThreeHead />
                    <ChatStepThreeCont setStepChat={setStepChat} />
                    <ChatStepThreeForm />
                </>
            }
        </div>
    )
}