import { baseApi, base_url } from "./baseApi";


export const PostConfigApi = async (postData: any) => {
    const data = await baseApi({ url: `${base_url}theme`, method: "POST" , body:JSON.stringify(postData) , token:true })
    return { data , postData  };
}

export const GetConfigApi = async () => {
    const data = await baseApi({ url: `${base_url}theme`, method: "GET" , token:true })
    return { data };
}


export const GetConfigByBotIDApi = async (bot_id:any) => {
    const data = await baseApi({ url: `${base_url}theme/${bot_id}`, method: "GET" , token:true  })
    return { data };
}